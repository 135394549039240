var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content continuing-education",
    class: _vm.device + "-newsCenter",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/news_banner.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    attrs: {
      src: require("../assets/banner/news_banner_mobile.jpg")
    }
  })]), false ? _c("div", {
    staticClass: "content news-nav",
    attrs: {
      id: "communication_concept"
    }
  }, [_c("div", {
    staticClass: "content-header"
  }, [_c("div", {
    staticClass: "concept-content",
    attrs: {
      id: "our_target"
    }
  }, [_c("div", {
    staticClass: "left animate__animated animate__fadeInLeft animate__slow"
  }, _vm._l(_vm.aLinks, function (item, index) {
    return _c("a", {
      key: index,
      staticClass: "navItem",
      class: {
        current: index == _vm.currentIndex
      },
      on: {
        click: function ($event) {
          return _vm.scrollIntoView(item.id, index);
        }
      }
    }, [_vm._m(0, true), _c("span", [_vm._v(_vm._s(item.name[parseInt(_vm.language - 1)]))])]);
  }), 0), _c("div", {
    staticClass: "right animate__animated animate__fadeInUp animate__slow"
  }, [_c("p", {
    staticClass: "intro-content"
  }, [_vm._v(_vm._s(_vm.mapText.communication_concept_content[parseInt(_vm.language - 1)]))])])])])]) : _vm._e(), _c("section", {
    ref: "news_center",
    staticClass: "continuing-education-content"
  }, [_c("div", {
    ref: "newsBlock_hd",
    staticClass: "newsBlock-hd animate__animated animate__slow animate__fadeInUp",
    class: {
      animate__fadeInUp: _vm.device == "mobile"
    }
  }, [_vm.language == 1 ? _c("h2", [_vm._v("新闻中心")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("PRESS ROOM")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("新聞中心")]) : _vm._e(), _c("div", {
    staticClass: "line"
  })]), _c("ul", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "company_dynamics",
    staticClass: "article-list animate__animated animate__slow animate__fadeInUp",
    class: {
      animate__fadeInUp: _vm.device == "mobile"
    },
    attrs: {
      id: "company_dynamics"
    }
  }, _vm._l(_vm.article_list, function (item, index) {
    return _c("li", {
      key: index,
      staticClass: "article-li",
      attrs: {
        "data-id": item.id
      }
    }, [_c("router-link", {
      staticClass: "article-a",
      attrs: {
        to: {
          path: "articleDetail",
          query: {
            id: item.id
          }
        }
      }
    }, [_c("div", {
      staticClass: "article-text"
    }, [_c("h3", {
      staticClass: "date-day"
    }, [_vm._v(_vm._s(_vm._f("monthFilter")(item.newsCreateTime)) + "." + _vm._s(_vm._f("dateFilter")(item.newsCreateTime)))]), _c("p", {
      staticClass: "article-title"
    }, [_vm._v(_vm._s(item.newsTitle))]), _c("div", {
      staticClass: "btn"
    }, [_c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })])])])], 1);
  }), 0), _vm.language == 1 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v("每页 " + _vm._s(_vm.page_size) + " 条")]), _c("span", [_vm._v("共 " + _vm._s(_vm.total_page) + " 页")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("上一页")]), _c("span", [_vm._v("第 " + _vm._s(_vm.page_num) + " 页")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("下一页")])]) : _vm._e(), _vm.language == 2 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v(_vm._s(_vm.page_size) + " entries per page")]), _c("span", [_vm._v("total " + _vm._s(_vm.total_page) + " page")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("page up")]), _c("span", [_vm._v("current " + _vm._s(_vm.page_num))]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("page down")])]) : _vm._e(), _vm.language == 3 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v("每頁 " + _vm._s(_vm.page_size) + " 條")]), _c("span", [_vm._v("共 " + _vm._s(_vm.total_page) + " 頁")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("上一頁")]), _c("span", [_vm._v("第 " + _vm._s(_vm.page_num) + " 頁")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("下一頁")])]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "circle"
  }, [_c("div", {
    staticClass: "inner"
  }), _c("div", {
    staticClass: "outer"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };