import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
const mapText = {
  communication_concept: ['传播理念', 'Communication Concept', '傳播理念'],
  news_center: ['新闻中心', 'News Center', '新聞中心'],
  communication_concept_content: ['积极推广和普及癌症早筛理念，推动癌症防治关口前移，让癌症早筛成为主动的健康管理习惯。', 'Actively promote and popularize the concept of early screening of cancer, promote the advance of cancer prevention and control, and make early screening of cancer an active health management habit.', '積極推廣和普及癌症早篩理念，推動癌症防治關口前移，讓癌症早篩成為主動的健康管理習慣。']
};
const aLinks = [{
  id: 'communication_concept',
  name: mapText.communication_concept
}, {
  id: 'news_center',
  name: mapText.news_center
}
// { id: 'company_dynamics', name: '公司动态' }
];
export default {
  name: "",
  data() {
    return {
      title: this.$api.api_title,
      article_list: [],
      page_num: 1,
      page_size: 8,
      total: 0,
      total_page: 0,
      aLinks,
      currentIndex: 0,
      loading: false,
      mapText
    };
  },
  computed: {
    ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {
    // $route(to,from) {
    //     // this.scrollInit()
    //     // scrollInit(this.$route.query.hash)
    //     if(this.$route.query.hash)
    //         this.changeTab(JSON.parse(this.$route.query.hash))
    // }
  },
  created() {
    // if(this.$route.query.last){
    //     this.select_tab = JSON.parse(this.$route.query.last)
    // }
    // if(this.$route.query.hash){
    //     this.changeTab(JSON.parse(this.$route.query.hash))
    // }
  },
  mounted() {
    this.getData();
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    // 首页/尾页
    goPage(page) {
      this.page_num = page;
      this.getData();
    },
    // 上一页/下一页
    changePage(type) {
      if (type == '-1' && this.page_num == 1) {
        return false;
      }
      if (type == '+1' && this.page_num == this.total_page) {
        return false;
      }
      this.page_num = type == '-1' ? this.page_num - 1 : this.page_num + 1;
      this.getData();
    },
    // 获取列表数据
    getData() {
      this.loading = true;
      this.$http.get(this.$api.news_gsList, {
        params: {
          pageNum: this.page_num,
          pageSize: this.page_size,
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        if (res.code == 0) {
          this.article_list = res.data.rows;
          this.total = res.data.total;
          this.total_page = this.total % this.page_size > 0 ? parseInt(this.total / this.page_size) + 1 : parseInt(this.total / this.page_size);
          this.loading = false;
        }
      });
    },
    changeRoute(row, index) {
      this.$router.push({
        path: '/articleDetail',
        query: {
          last: JSON.stringify(this.select_tab),
          path: '/popularization',
          module: this.module,
          params: JSON.stringify(row),
          page_num: this.page_num,
          page_size: this.page_size,
          index: index
        }
      });
    },
    scrollIntoView(id, index) {
      this.currentIndex = index;
      const el = document.getElementById(id);
      if (el) el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      });
    },
    handleScroll() {
      // 价值观
      const news_center = this.$refs.news_center.getBoundingClientRect().top;
      this.changeClass(news_center, this.$refs.newsBlock_hd, 'animate__fadeInLeft', 600);
      this.changeClass(news_center, this.$refs.company_dynamics, 'animate__fadeInUp', 600);
    },
    changeClass(distance, target, animate, top) {
      if (distance > top) return;
      target.classList.add(animate);
      target.classList.remove('display-none');
    }
  }
};